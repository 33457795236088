<template>
  <section class="expandable-deduction">
    <bg-skeleton v-if="loading" width="100%" height="50px" />
    <div v-else class="expandable-deduction-table">
      <table class="expandable-deduction-table-content">
        <thead class="main-thead">
          <tr>
            <th></th>
            <th>ID</th>
            <th>Nama Properti</th>
            <th>Status Masa Percobaan</th>
            <th>Kategori Penalti</th>
            <th>Total Pengurangan</th>
            <th>Disbursement</th>
            <th>Dibuat oleh</th>
            <th>Action</th>
          </tr>
        </thead>

        <template>
          <tbody>
            <tr class="expandable-deduction-table-content__empty">
              <td colspan="9">
                <bg-text size="body-4">Data tidak ditemukan</bg-text>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </section>
</template>

<script>
import { BgSkeleton, BgText } from 'bangul-vue';

export default {
  name: 'ExpandableDeductionTable',

  components: {
    BgSkeleton,
    BgText,
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  }
};
</script>

<style lang="scss" src="./ExpandableDeductionTable.scss" scoped />
